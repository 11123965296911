<style>
.subsection {
  font-size: 0.8em;
  color: #999;
}
</style>

<template>
  <modal name="CODIGO_FIREBASE" height="auto" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">

      <div class="form">

        <div class="xrow">
          <div class="col">
            <label>{{config ? config.desc : ''}}</label>
          </div>
        </div>

        <hr>

        <div class="xrow">
          <div class="col">
            <div class="form-group row" v-for="(label, field) in labels">
              <div class="col-1-1">
                <label :for="field">{{ label }}</label>
                <input class="form-control" type="text" v-model="fields[field]">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button :class="{ 'button button-success': true, 'button-disabled': false }" v-on:click="updateConfig(config)">
        Atualizar
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>
<script>
import clone from "just-clone";
import CloseModal from "../../../components/CloseModal";

export default {
  name: "CODIGO_FIREBASE",
  props: ["update", "close", "config"],
  components: {
    CloseModal,
  },
  data() {
    return {
      labels: {
        requestNotifcationTitle: "Título mensagem para solicitar autorizacão",
        requestNotifcationBody: "Subtítulo mensagem para solicitar autorizacão",
        serverKey: "Chave do servidor (serverKey)",
        inheritServerKey: "Chave herdada do servidor (inheritServerKey)",
        apiKey: "Chave de API da Web (apiKey)",
        authDomain: "Domínio de autenticação (authDomain)",
        databaseURL: "URL Firebase (databaseURL)",
        projectId: "ID de Projeto (projectId)",
        storageBucket: "Storage (storageBucket)",
        messagingSenderId: "Código do Remetente (messagingSenderId)",
        appId: "ID do App (appId)",
        measurementId: "ID de Análise (measurementId)",
      },
      fields: {},
    };
  },
  methods: {
    updateConfig() {
      let config = clone(this.config);
      config.valor = { ...this.fields };

      this.update(config);
    },
  },
  mounted() {
    this.fields = this.config.valor || {};
  },
};
</script>
